// extracted by mini-css-extract-plugin
export var approachInfoRoundBlock__between = "c2_xg";
export var approachInfoRoundBlock__card = "c2_xf";
export var approachInfoRoundBlock__contentFive = "c2_xr";
export var approachInfoRoundBlock__contentFour = "c2_xq";
export var approachInfoRoundBlock__contentOne = "c2_xm";
export var approachInfoRoundBlock__contentSix = "c2_xs";
export var approachInfoRoundBlock__contentThree = "c2_xp";
export var approachInfoRoundBlock__contentTwo = "c2_xn";
export var approachInfoRoundBlock__description = "c2_xl";
export var approachInfoRoundBlock__icon = "c2_xj";
export var approachInfoRoundBlock__imageDesktop = "c2_xh";
export var approachInfoRoundBlock__title = "c2_xk";